import React from 'react';
import Navigation from './components/Navigation';
import Cover from './components/Cover';
import About from './components/About';
import Workshop from './components/Workshop';
import Brands from './components/Brands';
import Form from './components/Form';
import Contact from './components/Contact';
import Location from './components/Location';
import Footer from './components/Footer';

//import styles
import './design/styles/base.scss';

//import Google Analytics
import ReactGA from 'react-ga4'
ReactGA.send({ hitType: "pageview", page: window.location.pathname })

function App() {
  return (
    <div className='outer-wrapper'>
      <Navigation/>
      <Cover/>
      <About/>
      <Workshop/>
      <Brands/>
      <Form/>
      <Contact/>
      <Location/>
      <Footer/>
    </div>
  );
}

export default App;
