import React, {useState, useEffect, useRef } from 'react';
import sanityClient from '../client';
import BlockContent from "@sanity/block-content-to-react";
import emailjs from '@emailjs/browser';

import '../design/styles/sections/form.scss';
import '../design/styles/elements/states-contact.scss';

const Form = () => {

    // state para mandar correo
    const [infoCorreo, guardarInfoCorreo] = useState({
        nombre: '',
        email: '',
        telefono: '',
        producto: '',
        tipo_producto: '',
        mensaje: ''
    });

    const formMail = useRef();

    const [form, setForm] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "form"]{
                text1,
                text2
            }`)
            .then((data) => {
                setForm(data[0])
            })
            .catch(console.error)
    }, []);

    if (!form) {
        return <div className="text-loading"></div>
    }

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }

            let section = document.querySelector('.block.form'),
                sectionRect = section.getBoundingClientRect(),
                navigation = document.querySelector('.navigation'),
                allOpts = navigation.querySelectorAll('.link'),
                navOpt = navigation.querySelector('.form-opt');


            if (sectionRect.top < window.innerHeight/1.5) {
                allOpts.forEach(opt => {
                    opt.classList.remove('active');
                });
                navOpt.classList.add('active');
            } else {
                navOpt.classList.remove('active');
            }
        };
        
        window.addEventListener('scroll', handleScroll);
    }, 1000);

    let listOpts = [];

    listOpts = [
        'Selecciona la marca',
        'Audemars Piguet',
        'Patek Philippe',
        'Rolex',
        'Richard Mille',
        'Vacheron Constantin',
        'Cartier',
        'Piaget',
        'Breguet',
        'Panerai',
        'Omega',
        'Jaeger-Lecoultre',
        'Iwc',
        'Bell & Ross',
        'Breitling',
        'Hublot',
        'Chanel',
        'Franck Muller',
        'Gérald Genta',
        'Ulysse Nardin',
        'Zenith',
        'Corum',
        'Graham',
        'Girard Perregaux',
        'Montblanc',
        'Roger Dubuis',
        'Tag Heuer',
        'Tudor',
        'Bvlgari',
        'Chopard',
        'Otro'
    ]

    const onChange = e => {
        let nameContainer = document.querySelector('.name-container');
        let phoneContainer = document.querySelector('.phone-container');
        let messageContainer = document.querySelector('.message-container');

        if (e.target.name === 'nombre') {
            if (e.target.value !=='') {
                nameContainer.classList.remove('invalid');
            } else {
                nameContainer.classList.add('invalid');
            }
        }

        if (e.target.name === 'telefono') {
            if (e.target.value === '' || e.target.value.length < 10 || e.target.value.length > 10) {
                phoneContainer.classList.add('invalid');
            } else {
                phoneContainer.classList.remove('invalid');
            }
        }

        if (e.target.name === 'mensaje') {
            if (e.target.value !=='') {
                messageContainer.classList.remove('invalid');
            } else {
                messageContainer.classList.add('invalid');
            }
        }

        if (e.target.value === 'Reloj') {
            listOpts = [
                'Selecciona la marca',
                'Audemars Piguet',
                'Patek Philippe',
                'Rolex',
                'Richard Mille',
                'Vacheron Constantin',
                'Cartier',
                'Piaget',
                'Breguet',
                'Panerai',
                'Omega',
                'Jaeger-Lecoultre',
                'Iwc',
                'Bell & Ross',
                'Breitling',
                'Hublot',
                'Chanel',
                'Franck Muller',
                'Gérald Genta',
                'Ulysse Nardin',
                'Zenith',
                'Corum',
                'Graham',
                'Girard Perregaux',
                'Montblanc',
                'Roger Dubuis',
                'Tag Heuer',
                'Tudor',
                'Bvlgari',
                'Chopard',
                'Otro'
            ]
        }
        if (e.target.value === 'Diamantes') {
            listOpts = []
        }
        if (e.target.value === 'Monedas de oro' || e.target.value === 'Oro') {
            listOpts = [
                'Selecciona la pieza',
                'Pieza de 10k',
                'Pieza de 14k',
                'Pieza de 18k',
                'Pieza de 21k'
            ]
        }
        updateList(listOpts);

        guardarInfoCorreo({
            ...infoCorreo,
            [e.target.name] : [e.target.value]
        });
    };

    const updateList = (list) => {

        const selectInput = document.querySelector('.select-wrapper');

        if (list.length !== 0) {
            selectInput.classList.remove('waiting');
            selectInput.innerHTML = `
                <select name='tipo_producto' id='select'>`+
                    list.map(opt => (
                        `<option value=${opt}>${opt}</option>`
                    )) +
                `</select>
            `;
        } else {
            selectInput.classList.add('waiting');
            selectInput.innerHTML = `<div></div>`;
        }
    }

    // Extrar de infoCorreo
    const {nombre, tipo_producto, telefono, mensaje} = infoCorreo;

    const reset = () => {
        guardarInfoCorreo({
            nombre: '',
            email: '',
            telefono: '',
            producto: '',
            tipo_producto: '',
            mensaje: ''
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();
        let selectElement = document.querySelector('select');
        let nameContainer = document.querySelector('.name-container');
        let phoneContainer = document.querySelector('.phone-container');
        let messageContainer = document.querySelector('.message-container');

        guardarInfoCorreo({
            ...infoCorreo,
            tipo_producto: selectElement.value
        })

        if (nombre.toString().trim() === '') {
            nameContainer.classList.add('invalid');
        } else {
            nameContainer.classList.remove('invalid');
        }

        if (telefono.toString().trim() === '' || telefono.toString().trim().length < 10 || telefono.toString().trim().length > 10) {
            phoneContainer.classList.add('invalid');
        } else {
            phoneContainer.classList.remove('invalid');
        }

        if (tipo_producto.toString().trim() === 'Selecciona' || tipo_producto.toString().trim() === '') {
            selectElement.classList.add('invalid');
        } else {
            selectElement.classList.remove('invalid');
        }

        if (mensaje.toString().trim() === '') {
            messageContainer.classList.add('invalid');
        } else {
            messageContainer.classList.remove('invalid');
        }

        // validar que no haya campos vacíos
        if(nombre.toString().trim() === '' || telefono.toString().trim() === '' || mensaje.toString().trim() === '') {
            console.log('error');
            return;
        }

        let statusMessage = document.querySelector('.status-message'),
            wrapperForm = document.querySelector('.wrapper-content-form');

        statusMessage.classList.remove('hidden');
        wrapperForm.classList.add('partial-hide');
        statusMessage.innerHTML = 
        `<div class="sending">
            <div class="message">
                <div class="icon icon-sending"></div>
                <div class="text">Enviando mensaje...</div>
            </div>
        </div>`;

        emailjs.sendForm('service_qbyofch', 'template_mwaoka7', formMail.current, 'SGhiIM0LPe0Gr-VhR')
            .then((result) => {
                setTimeout(() => {
                    statusMessage.innerHTML = 
                    `<div class="success">
                        <div class="message">
                            <div class="icon icon-success"></div>
                            <div class="text">¡Mensaje enviado!</div>
                        </div>
                    </div>`;
                    console.log(result.text);
                }, 3000);
            }, (error) => {
                setTimeout(() => {
                    statusMessage.innerHTML = '<div class="error">Hubo un error, inténtalo más tarde.</div>';
                    console.log(error.text);
                }, 3000);
            });
        
        setTimeout(() => {

            statusMessage.classList.add('hidden');
            wrapperForm.classList.remove('partial-hide');

            e.target.reset();
            reset();
        }, 12000);
    }

    return (
        <section className='block form' id='formulario'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='header'>
                        <div className='title text-inview'>{form.text1}</div>
                        <div className='text text-inview'>
                            <BlockContent
                                blocks={form.text2}
                                projectId='hhkfcvsk'
                                dataset='production'
                            />
                        </div>
                    </div>
                    <div className='content'>
                        <form
                            className='contact-form'
                            ref={formMail}
                            onSubmit={sendEmail}
                        >
                            <div className='status-message hidden'></div>
                            <div className='wrapper-content-form'>
                                <div className='input-container text-inview name-container'>
                                    <label htmlFor='name' className='input-label'>Nombre*</label>
                                    <input
                                        id='name'
                                        name='nombre'
                                        type='text'
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='flex-content'>
                                    <div className='input-container text-inview phone-container'>
                                        <label htmlFor='phone' className='input-label'>Teléfono*</label>
                                        <input
                                            id='phone'
                                            name='telefono'
                                            type='number'
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className='input-container text-inview mail-container'>
                                        <label htmlFor='email' className='input-label'>Email</label>
                                        <input
                                            id='email'
                                            name='email'
                                            type='email'
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className='radio-title text-inview'>¿Qué te interesa vender / empeñar?*</div>
                                <div className='inputs-wrapper'>
                                    <div className='input-container text-inview'>
                                        <input
                                            type='radio'
                                            id='watch'
                                            name='producto'
                                            value='Reloj'
                                            className='first-opt'
                                            onChange={onChange}
                                        />
                                        <label htmlFor='watch' className='radio-label'>Reloj</label>
                                    </div>
                                    <div className='input-container text-inview'>
                                        <input
                                            type='radio'
                                            id='diamonds'
                                            name='producto'
                                            value='Diamantes'
                                            onChange={onChange}
                                        />
                                        <label htmlFor='diamonds' className='radio-label'>Diamantes</label>
                                    </div>
                                    <div className='input-container text-inview'>
                                        <input
                                            type='radio'
                                            id='coins'
                                            name='producto'
                                            value='Monedas de oro'
                                            onChange={onChange}
                                        />
                                        <label htmlFor='coins' className='radio-label'>Monedas de oro</label>
                                    </div>
                                    <div className='input-container text-inview'>
                                        <input
                                            type='radio'
                                            id='gold'
                                            name='producto'
                                            value='Oro'
                                            onChange={onChange}
                                        />
                                        <label htmlFor='gold' className='radio-label'>Oro</label>
                                    </div>
                                </div>
                                <div className='input-container text-inview select-wrapper waiting'>
                                </div>
                                <div className='input-container text-inview message-container'>
                                    <label htmlFor='message' className='input-label'>Mensaje*</label>
                                    <textarea
                                        id='message'
                                        name='mensaje'
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='send-btn text-inview'>
                                    <input type='submit' value='Enviar'/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Form;