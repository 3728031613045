import React, {useState, useEffect} from 'react';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';

import '../design/styles/sections/location.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const Location = () => {

    const [location, setLocation] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "info"]{
                text6,
                "locationImage": image.asset->url
            }`)
            .then((data) => {
                setLocation(data[0])
            })
            .catch(console.error)
    }, []);

    if (!location) {
        return <div className="text-loading"></div>
    }

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }

            let section = document.querySelector('.block.location'),
                sectionRect = section.getBoundingClientRect(),
                navigation = document.querySelector('.navigation'),
                allOpts = navigation.querySelectorAll('.link'),
                navOpt = navigation.querySelector('.location-opt');


            if (sectionRect.top < window.innerHeight/1.5) {
                allOpts.forEach(opt => {
                    opt.classList.remove('active');
                });
                navOpt.classList.add('active');
            } else {
                navOpt.classList.remove('active');
            }
        };
        
        window.addEventListener('scroll', handleScroll);
    }, 1000);

    return (
        <section className='block location' id='ubicacion'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='header'>
                        <div className='title text-inview'>Ubicación</div>
                    </div>
                    <div className='content'>
                        <a className='image-wrapper image-inview' target='_blank' href={location.text6} rel="noreferrer">
                            <span>Ver Mapa</span>
                            <div className='image'>
                                <img
                                    src={urlFor(location.locationImage).url()}
                                    alt='Imagen Mapa'
                                />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Location;