import React, {useState, useEffect} from 'react';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from "@sanity/block-content-to-react";
import { HashLink } from 'react-router-hash-link';

import '../design/styles/sections/cover.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const Cover = () => {

    const [cover, setCover] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "cover"]{
                text1,
                text2,
                "coverImage": image.asset->url
            }`)
            .then((data) => {
                setCover(data[0])
            })
            .catch(console.error)
    }, []);

    if (!cover) {
        return <div className="text-loading"></div>
    }

    setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }

        /*let image = document.querySelector('.block.cover .image-wrapper');

        const mousemove = (event) => {
            
            let valueToRotate = event.pageX - (window.innerWidth/2);
            image.style.transform = 'rotate(' + (valueToRotate/50) + 'deg) translateY(' + event.pageY/50 + '%)';
        }

        window.addEventListener('mousemove', mousemove);*/
    }, 550);

    return (
        <section className='block cover' id='portada'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='content'>
                        <div className='flex-content'>
                            <div className='text-wrapper'>
                                <div className='title text-waiting'>{cover.text1}</div>
                                <div className='desc text-waiting'>
                                    <BlockContent
                                        blocks={cover.text2}
                                        projectId='hhkfcvsk'
                                        dataset='production'
                                    />
                                </div>
                                <HashLink
                                    smooth
                                    to='#formulario'
                                    className='see-more text-waiting'>
                                    <span className='btn-effect'>Contacto</span>
                                    <span className='btn-effect'>Contacto</span>
                                </HashLink>
                            </div>
                            <div className='image-wrapper image-waiting'>
                                <div className='image'>
                                    <img
                                        src={urlFor(cover.coverImage).url()}
                                        alt='Imagen Portada'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Cover;