import React, {useState, useEffect} from 'react';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from "@sanity/block-content-to-react";
import Slider from 'react-slick';

import '../design/styles/sections/about.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const About = () => {

    const [about, setAbout] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "about"]{
                text1,
                gallery
            }`)
            .then((data) => {
                setAbout(data[0])
            })
            .catch(console.error)
    }, []);

    if (!about) {
        return <div className="text-loading"></div>
    }

    const settings = {
        arrows: false,
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    autoplay: true,
                    autoplaySpeed: 5000,
                    infinite: true,
                    dots: true,
                    speed: 500,
                    fade: true,
                    cssEase: 'linear',
                }
            },
        ]
    }

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }

            let section = document.querySelector('.block.about'),
                sectionRect = section.getBoundingClientRect(),
                navigation = document.querySelector('.navigation'),
                navOpt = navigation.querySelector('.us-opt');


            if (sectionRect.top < window.innerHeight/1.5) {
                navOpt.classList.add('active');
            } else {
                navOpt.classList.remove('active');
            }
        };
        
        window.addEventListener('scroll', handleScroll);
    }, 1000);

    return (
        <section className='block about' id='nosotros'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='content'>
                        <div className='flex-content'>
                            <div className='carrousel-wrapper'>
                                <Slider {...settings} className='carrousel'>
                                    {about.gallery.map((image, index) => (
                                        <div className='item' key={index}>
                                            <div className='image-wrapper'>
                                                <div className='image image-inview'>
                                                    <img
                                                        src={urlFor(image.asset._ref).url()}
                                                        alt={'Imagen ' + index}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                            <div className='text-wrapper'>
                                <div className='title text-inview'>Nosotros</div>
                                <div className='text text-inview'>
                                    <BlockContent
                                        blocks={about.text1}
                                        projectId='hhkfcvsk'
                                        dataset='production'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;