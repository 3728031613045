import React, {useState, useEffect} from 'react';
import sanityClient from '../client';
import BlockContent from "@sanity/block-content-to-react";

import '../design/styles/components/footer.scss';

import logo from '../design/imgs/wag-logo.svg';
import facebook from '../design/imgs/fb.svg';
import instagram from '../design/imgs/ig.svg';

const Footer = () => {

    const [footer, setFooter] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "info"]{
                text1,
                text3,
                text4,
                text5,
                text6,
                text7,
                text8,
                text9
            }`)
            .then((data) => {
                setFooter(data[0])
            })
            .catch(console.error)
    }, []);

    if (!footer) {
        return <div className="text-loading"></div>
    }

    return (
        <footer className='footer'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='content'>
                        <div className='logo flex-child'>
                            <img
                                src={logo}
                                alt='Watches & Gold'
                            />
                        </div>
                        <div className='item flex-child'>
                            <div className='name'>Ubicación</div>
                            <div className='text-wrapper'>
                                <a target='_blank' rel="noreferrer" href={footer.text6}>{footer.text5}</a>
                            </div>
                        </div>
                        <div className='item flex-child'>
                            <div className='name'>Horario de Atención</div>
                            <div className='text-wrapper'>
                                <BlockContent
                                    blocks={footer.text9}
                                    projectId='hhkfcvsk'
                                    dataset='production'
                                />
                            </div>
                        </div>
                        <div className='item flex-child'>
                            <div className='name'>Atención al Cliente</div>
                            <div className='text-wrapper'>
                                <a target='_blank' rel="noreferrer" href={'tel:' + footer.text1}>TEL. +52 {footer.text1}</a>
                                <a target='_blank' rel="noreferrer" href={'tel:' + footer.text3}>CEL. +52 {footer.text3}</a>
                                <a target='_blank' rel="noreferrer" href={'mailto:' + footer.text4}>{footer.text4}</a>
                            </div>
                        </div>
                        <div className='item flex-child'>
                            <div className='name'>Comentarios</div>
                            <div className='links'>
                                <a className='social' target='_blank' rel="noreferrer" href={footer.text7}>
                                    <img
                                        src={facebook}
                                        alt='Facebook'
                                    />
                                </a>
                                <a className='social' target='_blank' rel="noreferrer" href={footer.text8}>
                                    <img
                                        src={instagram}
                                        alt='Instagram'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='copyright'>
                <div className='copyright-holder'>
                    <div>
                        Watches & Gold 2022 · <strong>Todos los derechos reservados.</strong>
                    </div>
                    <div>
                        <a href='aviso-de-privacidad' target='_bkank'>Aviso de privacidad</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;