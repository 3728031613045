import React, {useState, useEffect} from 'react';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import Slider from 'react-slick';

import '../design/styles/sections/brands.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const Brands = () => {

    const [brands, setBrands] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "brands"]{
                gallery
            }`)
            .then((data) => {
                setBrands(data[0])
            })
            .catch(console.error)
    }, []);

    if (!brands) {
        return <div className="text-loading"></div>
    }

    const settings = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear'
    }

    setTimeout(() => {

        if (window.innerWidth < 767) {
            
            let carrousel = document.querySelector('.block.brands .carrousel');
            if (carrousel) {
                let prev = carrousel.querySelector('.slick-prev'),
                next = carrousel.querySelector('.slick-next'),
                arrowsContainer = document.querySelector('.block.brands .arrows-container');
                if (arrowsContainer && prev && next) {
                    arrowsContainer.appendChild(prev);
                    arrowsContainer.appendChild(next);
                }
            }
        }

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }

            let section = document.querySelector('.block.brands'),
                sectionRect = section.getBoundingClientRect(),
                navigation = document.querySelector('.navigation'),
                allOpts = navigation.querySelectorAll('.link'),
                navOpt = navigation.querySelector('.brands-opt');


            if (sectionRect.top < window.innerHeight/1.5) {
                allOpts.forEach(opt => {
                    opt.classList.remove('active');
                });
                navOpt.classList.add('active');
            } else {
                navOpt.classList.remove('active');
            }
        };
        
        window.addEventListener('scroll', handleScroll);
    }, 1000);

    return (
        <section className='block brands' id='marcas'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='header'>
                        <div className='title text-inview'>Marcas</div>
                    </div>
                    <div className='content'>
                        <div className='carrousel-wrapper text-inview'>
                            <Slider {...settings} className='carrousel'>
                                <div className='item'>
                                    <div className='flex-content'>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[0].asset._ref).url()}
                                                    alt="Marca Uno"
                                                />
                                            </div>
                                        </div>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[1].asset._ref).url()}
                                                    alt="Marca Dos"
                                                />
                                            </div>
                                        </div>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[2].asset._ref).url()}
                                                    alt="Marca Tres"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-content'>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[3].asset._ref).url()}
                                                    alt="Marca Cuatro"
                                                />
                                            </div>
                                        </div>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[4].asset._ref).url()}
                                                    alt="Marca Cinco"
                                                />
                                            </div>
                                        </div>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[5].asset._ref).url()}
                                                    alt="Marca Seis"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='flex-content'>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[6].asset._ref).url()}
                                                    alt="Marca Siete"
                                                />
                                            </div>
                                        </div>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[7].asset._ref).url()}
                                                    alt="Marca Ocho"
                                                />
                                            </div>
                                        </div>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[8].asset._ref).url()}
                                                    alt="Marca Nueve"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-content'>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[9].asset._ref).url()}
                                                    alt="Marca Diez"
                                                />
                                            </div>
                                        </div>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[10].asset._ref).url()}
                                                    alt="Marca Once"
                                                />
                                            </div>
                                        </div>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[11].asset._ref).url()}
                                                    alt="Marca Doce"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='flex-content'>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[12].asset._ref).url()}
                                                    alt="Marca Trece"
                                                />
                                            </div>
                                        </div>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[13].asset._ref).url()}
                                                    alt="Marca Catorce"
                                                />
                                            </div>
                                        </div>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[14].asset._ref).url()}
                                                    alt="Marca Quince"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-content'>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[15].asset._ref).url()}
                                                    alt="Marca Dieciséis"
                                                />
                                            </div>
                                        </div>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[16].asset._ref).url()}
                                                    alt="Marca Diecisiete"
                                                />
                                            </div>
                                        </div>
                                        <div className='image-wrapper text-inview'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(brands.gallery[17].asset._ref).url()}
                                                    alt="Marca Dieciocho"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                            <div className='arrows-container'></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Brands;