import React from 'react';
import { HashLink } from 'react-router-hash-link';

import '../design/styles/components/navigation.scss';

const Navigation = () => {

    const expandMenu = () => {

        if (window.innerWidth < 767) {
            let toggleBtn = document.querySelector('.toggle-btn'),
                navigation = document.querySelector('.navigation'),
                listWrapper = document.querySelector('.list-wrapper'),
                body = document.querySelector('body');

            if (listWrapper.classList.contains('list-expanded')) {
                listWrapper.classList.remove('list-expanded');
                toggleBtn.classList.remove('expanded');
                navigation.classList.remove('expanded');
                body.classList.remove('no-scroll');
            } else {
                listWrapper.classList.add('list-expanded');
                toggleBtn.classList.add('expanded');
                navigation.classList.add('expanded');
                body.classList.add('no-scroll');
            }
        }
    }
    
    const handleScroll = event => {

        let navigation = document.querySelector('.navigation'),
            outerWrapper = document.querySelector('.outer-wrapper');
        let currentTop = window.scrollY;
        if (currentTop > 20) {
            navigation.classList.add('scrollTop');
            outerWrapper.classList.add('scrollTop');
        } else {
            navigation.classList.remove('scrollTop');
            outerWrapper.classList.remove('scrollTop');
        }

    }

    window.addEventListener('scroll', handleScroll);

    return (
        <nav className='navigation'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='content'>
                        <HashLink smooth to='#portada' className='logo'></HashLink>
                        <div className='list-wrapper'>
                            <div className='flex-content'>
                                <ul className='list'>
                                    <li className='opt'>
                                        <HashLink smooth to='#nosotros' className='link us-opt' onClick={() => expandMenu()}>Nosotros</HashLink>
                                    </li>
                                    <li className='opt'>
                                        <HashLink smooth to='#marcas' className='link brands-opt' onClick={() => expandMenu()}>Marcas</HashLink>
                                    </li>
                                    <li className='opt'>
                                        <HashLink smooth to='#formulario' className='link form-opt' onClick={() => expandMenu()}>Compra / Empeño</HashLink>
                                    </li>
                                    <li className='opt'>
                                        <HashLink smooth to='#contacto' className='link contact-opt' onClick={() => expandMenu()}>Contacto</HashLink>
                                    </li>
                                    <li className='opt'>
                                        <HashLink smooth to='#ubicacion' className='link location-opt' onClick={() => expandMenu()}>Ubicación</HashLink>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <button className="toggle-btn visible-xs toggle-btn-to-expand" onClick={() => expandMenu()}>
                            <span className="bar"></span>
                            <span className="bar"></span>
                            <span className="bar"></span>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navigation;