import React, {useState, useEffect} from 'react';
import sanityClient from '../client';

import '../design/styles/sections/contact.scss';

const Contact = () => {

    const [contact, setContact] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "info"]{
                text1,
                text2,
                text4,
                text5,
                text6
            }`)
            .then((data) => {
                setContact(data[0])
            })
            .catch(console.error)
    }, []);

    if (!contact) {
        return <div className="text-loading"></div>
    }

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }

            let section = document.querySelector('.block.contact'),
                sectionRect = section.getBoundingClientRect(),
                navigation = document.querySelector('.navigation'),
                allOpts = navigation.querySelectorAll('.link'),
                navOpt = navigation.querySelector('.contact-opt');


            if (sectionRect.top < window.innerHeight/1.5) {
                allOpts.forEach(opt => {
                    opt.classList.remove('active');
                });
                navOpt.classList.add('active');
            } else {
                navOpt.classList.remove('active');
            }
        };
        
        window.addEventListener('scroll', handleScroll);
    }, 1000);

    return (
        <section className='block contact' id='contacto'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='header'>
                        <div className='title text-inview'>Contacto</div>
                    </div>
                    <div className='content'>
                        <div className='flex-content'>
                            <div className='item'>
                                <div className='icon phone text-inview'></div>
                                <div className='name text-inview'>Teléfono.</div>
                                <div className='text-wrapper text-inview'>
                                    <a target='_blank' rel="noreferrer" href={'tel:' + contact.text1}>TEL. +52 {contact.text1}</a>
                                    <a target='_blank' rel="noreferrer" href={'tel:' + contact.text2}>CEL. +52 {contact.text2}</a>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='icon pin text-inview'></div>
                                <div className='name text-inview'>Sucursal.</div>
                                <div className='text-wrapper text-inview'>
                                    <a target='_blank' rel="noreferrer" href={contact.text6}>{contact.text5}</a>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='icon mail text-inview'></div>
                                <div className='name text-inview'>Email.</div>
                                <div className='text-wrapper text-inview'>
                                    <a target='_blank' rel="noreferrer" href={'mailto:' + contact.text4}>{contact.text4}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;