import React, {useState, useEffect} from 'react';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from "@sanity/block-content-to-react";

import '../design/styles/sections/workshop.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const Workshop = () => {

    const [workshop, setWorkshop] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "workshop"]{
                text,
                "workshopImage": image.asset->url
            }`)
            .then((data) => {
                setWorkshop(data[0])
            })
            .catch(console.error)
    }, []);

    if (!workshop) {
        return <div className="text-loading"></div>
    }

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);

        /*let image = document.querySelector('.block.workshop .image-wrapper');
    
        const mousemove = (event) => {
            
            let valueToRotate = event.clientX - (window.innerWidth/2),
                valueToTranslate = event.clientY - (window.innerWidth/4);
            image.style.transform = 'rotate(' + (valueToRotate/50) + 'deg) translate3d(' + valueToRotate/300 + '%, ' + valueToTranslate/200 + '%, 0)';
        }
    
        window.addEventListener('mousemove', mousemove);*/
    }, 1000);

    return (
        <section className='block workshop'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='content'>
                        <div className='flex-content'>
                            <div className='image-wrapper image-inview'>
                                <div className='image'>
                                    <img
                                        src={urlFor(workshop.workshopImage).url()}
                                        alt='Imagen Portada'
                                    />
                                </div>
                            </div>
                            <div className='text-wrapper'>
                                <div className='title text-inview'>Taller de Relojes</div>
                                <div className='text text-inview'>
                                    <BlockContent
                                        blocks={workshop.text}
                                        projectId='hhkfcvsk'
                                        dataset='production'
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Workshop;